export function calculateTotalDuration(chapters) {

    let totalDurationSecs = 0;

    // Count seconds of all valid chapters
    if (chapters) {
        chapters.map((chapter, index) => {

            if (chapter.duration && chapter.duration > 0) {
                totalDurationSecs += chapter.duration;
            }

            return null;
        })
    }

    let timespan = new Date(totalDurationSecs * 1000).toISOString();
    let hour = timespan.substr(11, 2);
    let min = timespan.substr(14, 2);

    // Return MM:ss
    return hour + 'Std. ' + min + 'min.';
}

export function formateDuration(duration) {

    let min = parseInt(duration / 60).toString().padStart(2, '0');
    let sec = parseInt(duration % 60).toString().padStart(2, '0');

    // Return MM:ss
    return min + ":" + sec;
}

export function trimText(text, trimLength) {
    if (trimLength) {
        if (text.length > trimLength) {
            return text.substring(0, trimLength) + "...";
        } else {
            return text;
        }
    }

    return text;
}

export function getImageUrl(imageObj = '') {
    if (imageObj !== null) {

        let baseURL = 'https://villagustomvp.netlify.app';
        if (typeof window !== 'undefined') {
            baseURL = window.location.origin;
        }

        // Fluid image/ responsive images with Gatsby plugin
        if (typeof imageObj === 'object') {

            if (imageObj.image) {
                return baseURL + imageObj.image.childImageSharp.fluid.src;
            }
            if (imageObj.childImageSharp && imageObj.childImageSharp.fluid) {
                return baseURL + imageObj.childImageSharp.fluid.src;
            }
            if (imageObj.source) {
                return imageObj.source
            }
        }
        // String only
        else if (typeof imageObj === 'string') {
            return baseURL + imageObj.replace('../../../static', '');
        }
    }
    return '';
}

export function isMobile() {
    if (typeof navigator !== `undefined`) {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }

    return false;
}

export function getUrlParameter(param) {
    if (typeof navigator !== 'undefined') {
        if (param) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(param);
        }
    }

    return false;
}

export function getCourseUrl(pageId) {

    let baseURL = 'https://villagusto.com';
    if (pageId !== null) {
        return baseURL + '/course/course-' + pageId.toString();
    } else {
        return baseURL;
    }
}

export function isSwitzerland() {
    if (typeof navigator !== `undefined`) {
        return navigator.language.indexOf('CH') !== -1;
    } else return false;
}

export function getLocalePrice(priceEur, withCurrency = false) {

    let price = priceEur;

    return `${price}€`;

    // TODO Schweiz
    // Same price EUR = CHF
    /*if (isSwitzerland()) {
        price = (price * 1.1049).toFixed(0);
    }

    if (withCurrency) {
        return `${isSwitzerland() ? 'CHF ' : ''}${price}${!isSwitzerland() ? '€' : ''}`
    }*/
}