import React from 'react'
import { Link } from 'gatsby'

import logo from '../../img/logo-black.svg'
import facebook from '../../img/facebook.svg'
import instagram from '../../img/instagram.svg'
import pinterest from '../../img/pinterest.svg'
import { FooterWrapper, SocialIcon, SocialWrapper } from './Footer.styles'

const Footer = class extends React.Component {
  openCookieConsent = () => {
    if (typeof window !== `undefined`) {
      window.klaro.show();
    }
  }

  render() {
    return (
      <FooterWrapper className={''}>
        <SocialWrapper>

          <SocialIcon title='instagram' href='https://www.instagram.com/villagusto_com/' target={'_blank'} rel={'noreferrer'}>
            <img
              src={instagram}
              alt='Instagram'
            />
          </SocialIcon>
          <SocialIcon title='facebook' href='https://fb.me/villagusto.01' target={'_blank'} rel={'noreferrer'}>
            <img
              src={facebook}
              alt='Facebook'
            />
          </SocialIcon>
          <SocialIcon title='pinterest' href='https://www.pinterest.de/villagusto' target={'_blank'} rel={'noreferrer'}>
            <img
              src={pinterest}
              alt='Pinterest'
            />
          </SocialIcon>
        </SocialWrapper>
        <div className=''>
          <section className='menu'>
            <ul className='menu-list'>
              <li>
                <Link className='navbar-item' to='/registration'>
                  So funktioniert's
                </Link>
              </li>
              <li>
                <Link to='/impressum' className='navbar-item'>
                  Impressum
                </Link>
              </li>
              <li>
                <Link to='/privacy' className='navbar-item'>
                  Datenschutz
                </Link>
              </li>
              <li>
                <Link className='navbar-item' to='/agb'>
                  AGB
                </Link>
              </li>
              <li>
                <span className='navbar-item' role='button' tabIndex={0} onClick={this.openCookieConsent} aria-hidden='true'>
                  Cookies
                </span>
              </li>
              <li>
                <span className={'copyright'}>© Gustini – 2021</span>
              </li>
            </ul>
          </section>
        </div>

        <div className='has-text-centered'>
          <img
            src={logo}
            alt='Villagusto'
          />
        </div>
      </FooterWrapper >
    )
  }
}

export default Footer
