import styled from "styled-components";
import { breakpoints, colors, fonts, margins } from "../../styles/styles";

export const ButtonEl = styled.button`
    background: ${colors.white};
    backdrop-filter: blur(15px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 32px;
    border: none;
    outline: none;
    padding: 12px 16px;
    cursor: pointer;
    margin-right: ${margins.S};
    margin-bottom: ${margins.S};
    transition: background-color 0.5s;

    &.filled, &.primary {
        background-color: ${colors.primary};
        backdrop-filter: unset;

        &:hover {
            background-color: #E82723;
        }
    }

    &:last-of-type {
        margin-right: 0px;
    }

    &.icon-button {
        width: 48px;
    }

    &.sticky-purchase {
        position: fixed;
        bottom: 50px;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 8;
        display: flex;
        justify-content: center;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.3));
        max-width: 315px;
        min-width: 300px;
    }

    a, span {
        text-decoration: none !important;
        font-family: ${fonts.regular};
        font-size: 16px !important;
        font-style: normal;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: white;
        cursor: pointer;

        @media(${breakpoints.M}) {
            font-size: 24px;
        }
    }

    img {
        width: 24px;
        height: 24px;
    }

    &.disabled {
        pointer-events: none;
    }
`;

export const MainText = styled.span`
    font-weight: 700;
    text-transform: uppercase;
`;

export const SmallText = styled.span`
    font-weight: 500;
    margin-left: ${margins.S};
    font-size: 15px;
`;