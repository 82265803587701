
export const margins = {
    S: '8px',
    M: '16px',
    L: '24px',
    XL: '80px'
}

export const paddings = {
    S: '8px',
    M: '16px',
    L: '24px',
    XL: '80px'
}

export const breakpointValues = {
    S: '0px',
    M: '835px',
    L: '1440px',
}

export const breakpoints = {
    S: 'min-width: 0px',
    M: 'min-width: 835px',
    L: 'min-width: 1440px',
}

export const colors = {
    buttonPrimary: '#F54B47',
    primary: '#F54B47',
    secondary: '#B8A78C',
    background: '#F9F3E9',
    white: 'rgba(255, 255, 255, 0.25)'
}

export const fonts = {
    regular: 'Brandon Grotesque, Arial',
    heading: 'Bodoni 72, Arial',
    medium: 'Brandon Grotesque Medium, Arial',
    bold: 'Brandon Grotesque Bold, Arial',


}