import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
//import { useLocation } from "@reach/router" // this helps tracking the location

// define a minimal configuration
export const CookieConfig = {
    elementID: 'klaro-cookie-content',
    acceptAll: true,
    services: [
        {
            name: 'google-analytics',
            purposes: ['analytics'],
            cookies: [
                /^_ga(_.*)?/, // we delete the Google Analytics cookies if the user declines its use
                /^ _gid(_.*) ? /
            ],
        },
        {
            name: 'google-tag-manager',
            purposes: ['analytics'],
            cookies: [
            ],
        }
    ],
    callback: function (consent, service) {

        // Allow cookie
        if (service.name === 'google-tag-manager') {
            document.cookie = "gatsby-gdpr-google-analytics=" + consent + "; max-age=" + 30 * 24 * 60 * 60;
        } else if ('google-analytics') {
            document.cookie = "gatsby-gdpr-google-tagmanager=" + consent + "; max-age=" + 30 * 24 * 60 * 60;
        }

        if (typeof window !== 'undefined') {
            initializeAndTrack(window.location);
        }
    },
};