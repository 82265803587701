import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'
import '../../styles/all.scss'
import '../../styles/snipcart.scss'
import 'lazysizes'
import Cookies from '../cookies/Cookies'
import { getImageUrl } from '../../utils/helpers'
import { netlifyAuth } from '../../utils/auth'

import CountdownBanner from '../countdown-banner/CountdownBanner'
import Banner from '../banner/Banner'

import FavIcon60 from '../../img/fav/apple-icon-60x60.png'
import FavIcon72 from '../../img/fav/apple-icon-72x72.png'
import FavIcon120 from '../../img/fav/apple-icon-120x120.png'
import FavIcon144 from '../../img/fav/apple-icon-144x144.png'
import FavIcon180 from '../../img/fav/apple-icon-180x180.png'
import FavIcon192 from '../../img/fav/android-icon-192x192.png'
import FavIcon32 from '../../img/fav/favicon-32x32.png'
import FavIcon96 from '../../img/fav/favicon-96x96.png'
import FavIcon16 from '../../img/fav/favicon-16x16.png'
import MSFavIcon from '../../img/fav/ms-icon-144x144.png'

//const Snipcart = loadable(() => import('../snipcart/Snipcart'));

const TemplateWrapper = class extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      hasLoaded: false
    }
  }

  componentDidMount() {
    this.setState({ hasLoaded: true });

    if (typeof window !== 'undefined' && window.Snipcart !== undefined && window.localStorage.getItem('snipcart_sessionid') === null) {

      window.Snipcart.events.on('customer.registered', (customer) => {
        netlifyAuth.signin(customer.sessionToken);
      });

      // Snipcart Customer Login
      window.Snipcart.events.on('customer.signedin', (customer) => {
        netlifyAuth.signin(customer.sessionToken);
      });

      // Snipcart Customer Logout
      window.Snipcart.events.on('customer.signedout', (customer) => {
        netlifyAuth.signout();
      });

      // TODO Set currency and locale Schweiz
      /*if (isSwitzerland()) {
        window.Snipcart.api.session.setCurrency('chf');
      }*/
    }
  }

  render() {
    return (
      <div>
        {/*this.state.hasLoaded && <Snipcart />*/}
        <Helmet>
          <html lang="de" />
          <title>{this.props.seo_title}</title>
          <meta name="description" content={this.props.seo_description} />
          <meta property="og:site_name" content="Villa Gusto" />
          <meta property="og:title" content={this.props.seo_title} />
          <meta property="og:description"
            content={this.props.seo_description} />
          <meta property="og:image" content={getImageUrl(this.props.seo_image)} />
          <meta property="og:type" content="website" />
          <meta property="og:type" content="business.business" />
          <link rel="apple-touch-icon" sizes="60x60" href={FavIcon60} />
          <link rel="apple-touch-icon" sizes="72x72" href={FavIcon72} />
          <link rel="apple-touch-icon" sizes="120x120" href={FavIcon120} />
          <link rel="apple-touch-icon" sizes="144x144" href={FavIcon144} />
          <link rel="apple-touch-icon" sizes="180x180" href={FavIcon180} />
          <link rel="icon" type="image/png" sizes="192x192" href={FavIcon192} />
          <link rel="icon" type="image/png" sizes="32x32" href={FavIcon32} />
          <link rel="icon" type="image/png" sizes="96x96" href={FavIcon96} />
          <link rel="icon" type="image/png" sizes="16x16" href={FavIcon16} />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content={MSFavIcon} />
          <meta name="theme-color" content="#ffffff" />
          <meta name="facebook-domain-verification" content="kd5k8uvfasbduk3m0loxzk1qcdgic4" />
        </Helmet>
        {/* STICKY BANNER */}
        <Banner />
        {/* COUTNDOWN TIMER */}
        <CountdownBanner />
        <Navbar />
        <Cookies />
        <div>{this.props.children}</div>
        <Footer />
      </div>
    )
  }
}


export default TemplateWrapper
