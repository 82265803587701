import React from 'react'
import PropTypes from 'prop-types'
import { ButtonEl, MainText, SmallText } from './Button.styles'
import { Link } from '@reach/router'

const Button = class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPurchaseButtonSticky: false,
    }

    // TODO: no sticky prop?
    //console.log(this.props, props.stickyThreshold)
  }

  componentDidMount() {

    // Add scroll event listener
    this.toggleSticky();
  }

  toggleSticky = () => {


    // Only make sticky if threshold prop is passed
    if (!this.props.stickyThreshold) {
      return;
    }

    document.onscroll = () => {
      const currentScrollY = window.scrollY;

      // Make purchase button sticky
      if (currentScrollY > this.props.stickyThreshold) {
        this.setState({ isPurchaseButtonSticky: true });
      } else {
        this.setState({ isPurchaseButtonSticky: false });
      }
    };
  }

  render() {
    return (
      <ButtonEl className={`${this.props.className || ''} ${this.props.filled ? 'filled' : ''} ${this.props.disabled ? 'disabled' : ''} ${this.state.isPurchaseButtonSticky ? 'sticky-purchase' : ''}`} onClick={this.props.onClick} type={this.props.type || 'button'}>
        {this.props.to &&
          <Link to={this.props.to}>
            {this.props.text && <MainText>{this.props.text}</MainText>}
            {this.props.smallText && <SmallText>{this.props.smallText}</SmallText>}
            {this.props.icon && <img src={this.props.icon} alt={`Button mit Link, ${this.props.text}`} />}
          </Link>
        }
        {/* NO LINK */}
        {this.props.to === undefined &&
          <span>
            {this.props.text && <MainText>{this.props.text}</MainText>}
            {this.props.smallText && <SmallText>{this.props.smallText}</SmallText>}
            {this.props.icon && <img src={this.props.icon} alt={`einfacher Button, ${this.props.text}`} />}
          </span>
        }
      </ButtonEl>
    )
  }
}

Button.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  smallText: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  filled: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,

}

export default Button
