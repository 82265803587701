import React from 'react'
import { BannerWrapper, BannerUSPText, BannerUSPImage, BannerUSPWrapper } from './Banner.styles'
import CheckSVG from '../../img/check.svg'
import { graphql, StaticQuery } from 'gatsby'

class Banner extends React.Component {

  render() {

    let data = {}
    if (this.props.data && this.props.data.markdownRemark && this.props.data.markdownRemark.frontmatter) {
      data = this.props.data.markdownRemark.frontmatter;
    }

    if (data.showUSPBanner) {
      return (
        <BannerWrapper className={`banner`}>
        {data.bannerUSP && data.bannerUSP.map((item, index) => {
          return <BannerUSPWrapper key={index}>
            <BannerUSPImage src={CheckSVG} />
            <BannerUSPText>{item.text}</BannerUSPText>
          </BannerUSPWrapper>
        })}
      </BannerWrapper>);
    } else return <></>;
  }
}


export default (props) => (
  <StaticQuery
    query={graphql`
    query BannerQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          showUSPBanner
          bannerUSP {
            text
          }
        }
      }
    }
    `}
    render={(data) => <Banner data={data} {...props} />}
  />
)
