import React from 'react'
import { Link } from 'gatsby'
import LogoWhiteSVG from '../../img/logo-white.svg'
import LogoBlackSVG from '../../img/logo-black.svg'
import BurgerSVG from '../../img/burger.svg'
import BurgerStickySVG from '../../img/burger-sticky.svg'
import CloseMenuSVG from '../../img/menu-close.svg'
import { netlifyAuth } from '../../utils/auth'
import { NavbarWrapper, Logo, MobileMenuItem, CheckoutButton, CloseMenuIcon, BurgerIcon, MenuWrapper, StickyHeader, StickyInner, Header, BurgerMenuIcon } from './Navbar.styles'
import Button from '../../components/button/Button'
import facebook from '../../img/facebook.svg'
import instagram from '../../img/instagram.svg'
import pinterest from '../../img/pinterest.svg'
import { SocialIcon, SocialWrapper } from '../footer/Footer.styles'
import Banner from '../banner/Banner'
import { graphql, StaticQuery } from 'gatsby'


const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      isSticky: false,
    }

    // Scroll sticky header
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.headerFixedScroll);
    }
  }

  /**
  * Make header sticky if certain threshold is met.
  */
  headerFixedScroll = () => {

    const header = document.querySelector('nav');
    const stickyHidden = 300;
    const stickyVisible = 500;

    if (header) {
      if (window.pageYOffset < stickyHidden) {
        this.setState({ isSticky: false });
      }
      else if (window.pageYOffset > stickyHidden && window.pageYOffset < stickyVisible) {
        this.setState({ isSticky: false });
      }
      else if (window.pageYOffset > stickyVisible) {
        this.setState({ isSticky: true });
      }
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  loginClick = () => {
    netlifyAuth.authenticate();
    this.toggleHamburger();
  }

  logoutClick = () => {
    netlifyAuth.signout();
    this.toggleHamburger();
  }

  render() {

    // Static Query data
    let data = {}
    if (this.props.data && this.props.data.markdownRemark && this.props.data.markdownRemark.frontmatter) {
      data = this.props.data.markdownRemark.frontmatter;
    }

    // Countdown calcs
    let endDateCountdown = Date.parse(data.endDateCountdown);
    let currentDate = new Date().getTime();

    let difference = endDateCountdown - currentDate;
    const today = new Date().getTime();
    let isCountdownActive = Date.parse(data.startDateCountdown) <= today && data.showCountdownBanner && difference > 0;

    return (
      <NavbarWrapper
        className={`${this.state.navBarActiveClass} navbar ${isCountdownActive ? 'hasCountdown' : ''}`}
        role="navigation"
        aria-label="main-navigation"
      >
        {/* STICKY HEADER */}
        <StickyHeader className={`navbar-brand sticky-header ${this.state.isSticky ? 'sticky-visible' : ''} ${this.state.navBarActiveClass}`}>
          <Banner />
          <StickyInner className={`${this.state.active ? 'is-active' : ''}`}>
            <Link to="/" title="Logo">
              <Logo src={LogoBlackSVG} alt="Villagusto" className={'sticky-logo'} />
            </Link>
            {/* Hamburger menu */}
            <BurgerMenuIcon
              className={`burger close-sticky icon-button ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
              src={this.state.active ? CloseMenuSVG : BurgerStickySVG}
              alt={'Menü schließen'}
            />
          </StickyInner>
        </StickyHeader>

        {/* NORMAL HEADER */}
        <Header className="navbar-brand header">
          {!this.state.active && <>
            <Link to="/" title="Logo">
              <Logo src={LogoWhiteSVG} alt="Villagusto" className={'sticky-logo logo-mobile'} />
            </Link>
            {/* Hamburger menu */}
            <BurgerIcon
              className={`burger icon-button ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
              src={BurgerSVG}
              alt={'Menü schließen'}
            />
          </>
          }
        </Header>

        {/* MENU */}
        <>
          <MenuWrapper
            id="navMenu"
            className={`${this.state.navBarActiveClass} ${this.state.isSticky ? 'menu-sticky' : ''} ${isCountdownActive ? 'hasCountdown' : ''}`}
          >
            <div className="navbar-brand">
              <Link to="/" title="Logo">
                <Logo src={LogoBlackSVG} alt="Villagusto" className={`logo-normal ${this.state.isSticky ? 'hide-logo' : ''}`} />
              </Link>
              {/* Close menu */}
              <CloseMenuIcon
                className={`icon-button close ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
                src={CloseMenuSVG}
                alt={'Menü schließen'}
              />
            </div>
            <MobileMenuItem className="navbar-item" rel="noopener noreferrer" href={'/#courses'} onClick={this.toggleHamburger}>
              Kochkurse
            </MobileMenuItem>
            <MobileMenuItem className="navbar-item" rel="noopener noreferrer" href={'#voucher'} onClick={this.toggleHamburger}>
              Gutscheine
            </MobileMenuItem>
            <MobileMenuItem className="navbar-item snipcart-checkout" rel="noopener noreferrer" href={'/'} onClick={this.openCart}>
              <CheckoutButton className={'snipcart-checkout'} title={'Warenkorb'} onClick={this.toggleHamburger}>Warenkorb</CheckoutButton>
            </MobileMenuItem>
            <MobileMenuItem >
              {!netlifyAuth.getIsAuthenticated() &&
                <Button text={'Jetzt anmelden'} filled={true} className={'login'} onClick={this.loginClick} />
              }
              {netlifyAuth.getIsAuthenticated() &&
                <Button text={'Abmelden'} filled={true} className={'login'} onClick={this.logoutClick} />
              }
            </MobileMenuItem>
            <SocialWrapper className={'navbar'}>
              <SocialIcon title="instagram" href="https://instagram.com" target={'_blank'} onClick={this.toggleHamburger} rel={'noreferrer'}>
                <img
                  src={instagram}
                  alt="Instagram"
                />
              </SocialIcon>
              <SocialIcon title="facebook" href="https://facebook.com" target={'_blank'} onClick={this.toggleHamburger} rel={'noreferrer'}>
                <img
                  src={facebook}
                  alt="Facebook"
                />
              </SocialIcon>
              <SocialIcon title="pinterest" href="https://pinterest.com" target={'_blank'} onClick={this.toggleHamburger} rel={'noreferrer'}>
                <img
                  src={pinterest}
                  alt="Pinterest"
                />
              </SocialIcon>
            </SocialWrapper>
          </MenuWrapper>
        </>

      </NavbarWrapper >
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
    query CountdownBannerNavQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          showCountdownBanner
          startDateCountdown
          endDateCountdown
        }
      }
    }
    `}
    render={(data) => <Navbar data={data} {...props} />}
  />
)
