import styled from "styled-components";
import { breakpoints, fonts, margins, paddings } from "../../styles/styles";

export const NavbarWrapper = styled.nav`
    background-color: transparent;
    padding-top: ${paddings.L};
    position: absolute;
    right: 0;
    top: 0px;
    left: 0px;
    z-index: 100;
    flex-flow: column;

    &.hasCountdown {
      top: 40px;

      @media(${breakpoints.M}) {
        top: 0px;
      }

      &.is-active {
        z-index: 2001 !important;
      }
    }

    @media(${breakpoints.M}) {
        padding-top: calc(${paddings.XL} + 20px);
    }

    .navbar-brand {
        padding: 0px ${paddings.L};
        padding-top: ${paddings.M};
        align-items: center;
        width: 100%;
        transition: background-color 0.2s;
        background-color: rgba(255, 255, 255, 0);

        @media(${breakpoints.M}) {
            padding: 0px ${paddings.XL};
        }

        @media(${breakpoints.L}) {
            margin: auto;
            max-width: 1265px;
        }

        &.sticky-header {
            max-width: unset;
            z-index: 1000;
            flex-direction: column;
        }
    }
`;

export const Logo = styled.img`
    background-color: transparent;
    height: 40px;
    max-height: unset !important;
    transition: opacity 0.2s;

    @media(${breakpoints.L}) {
        height: 60px;
    }

    &.hide-logo {
        opacity: 0;
    }
`;

export const MobileMenuItem = styled.a`
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    justify-content: center;
    color: black;
    padding: ${paddings.L} 0px;
    cursor: pointer;
    text-align: center;
    background-color: white;
    display: flex;
    position: relative;
    cursor: pointer;

    &:hover {
        color: black !important;

        &:not(.snipcart-checkout) {
            text-decoration: none;
        }
    }

    &.social-nav {
        padding-top: ${margins.XL};
        padding-bottom: calc(2* ${paddings.L});
    }

    button span {
        text-decoration: none !important;
    }
`;

export const CheckoutButton = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const CloseMenuIcon = styled.img`
    display: block;
    position: relative;
    margin-left: auto;
    cursor: pointer;
    height: 40px;
    margin-top: 2px;

    @media(${breakpoints.L}) {
        height: 60px;
    }
`;

export const BurgerMenuIcon = styled.img`
    display: block;
    position: relative;
    margin-left: auto;
    cursor: pointer;
`;

export const BurgerIcon = styled.img`
    cursor: pointer;
    display: block;
    position: relative;
    margin-left: auto;

    height: 40px;

    @media(${breakpoints.L}) {
        height: 60px;
    }
`;

export const MenuWrapper = styled.div`
    position: fixed;
    width: 100%;
    background-color: white;
    top: 0px;
    padding-top: ${margins.M};
    z-index: 12;
    opacity: 0;
    transition: opacity 0.2s;
    transition-delay: 0s;
    overflow: hidden;
    height: 0;

    .login, .logout {
        display: flex;
        margin: ${paddings.L} auto !important;
    }

    &.is-active {
        opacity: 1;
        overflow: visible;
        height: 100vh;
        padding-top: ${paddings.M};

        &.hasCountdown {
          padding-top: 40px;

          @media(${breakpoints.M}) {
            padding-top: calc(${paddings.XL} + 20px);
          }
        }

        @media(${breakpoints.M}) {
          padding-top: calc(${paddings.XL} + 20px);
        }
    }

    @media(${breakpoints.M}) {
      padding-top: calc(${paddings.XL} + 20px);
    }

    &.menu-sticky .close {
      display: none;

      @media(${breakpoints.M}) {
        display: block;
      }
    }
`;

export const StickyHeader = styled.div`
    background-color: white;
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    opacity: 0;
    transition: opacity 0.2s ease-in;
    padding-top: 5px;
    padding-bottom: 5px;

    .banner {

      margin-top: calc(-1 * ${margins.M});

      @media(${breakpoints.M}) {
        opacity: 0;
        transition: opacity 0.2s ease-in;
        margin-top: 0px;
      }
    }

    &.sticky-visible {
        opacity: 1;
        background-color: white;

        @media(${breakpoints.M}) {
          .banner {
            opacity: 1;
          }
        }
    }

    &.is-active {

        .sticky-logo {

            @media(${breakpoints.M}) {
              height: 55.7px !important;
              margin-top: ${paddings.XL};
            }
        }
    }

    a {
        height: 35px;

        .sticky-logo {
            height: 35px;
            width: auto;
        }
    }

    .sticky-logo {
        height: 100px;
        transition: height 0.2s ease-in, margin-top 0.2s ease-in;
    }

    .burger {
        opacity: 1;
        transition: opacity 0.2s;
    }

`;

export const StickyInner = styled.div`
    max-width: calc(1265px - 2*${margins.XL});
    width: 100%;
    display: flex;
    margin: auto;
    padding: ${paddings.S} 0px;

    &.is-active {

      .close-sticky {
        width: 40px;

        @media(${breakpoints.M}) {
          display: none;
        }
      }
    }
`;

export const Header = styled.div`
    z-index: 2;
`;
