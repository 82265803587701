export const netlifyAuth = {
    getSnipcartAPIKey() {
        let testing = false;
        if (testing) {
            return 'ZDk1Njc4MzEtMTRhZS00OWU0LTg2YjktNWZmYzAyODk1NTAzNjM3NDI0OTQ4ODY4OTg1ODA0';
        } else {
            return 'MDkzNzdmZjUtOWZmYS00MWIyLThhODktYzM1NjM3NmQwZWFmNjM3NDI0OTQ4ODY4OTg1ODA0';
        }
    },
    /**
     * Opens the signup modal (default) or login modal.
     * @param {function} callback function that is called on success
     * @param {string} modalType signup or login, default: signup
     */
    authenticate() {
        if (typeof window !== 'undefined') {
            window.location.href = window.location.href + '#/cart/signin';
        }
    },
    /**
     * Checks if any user is already authenticated.
     * @returns {boolean}
     */
    getIsAuthenticated() {
        if (typeof window !== 'undefined') {
            return window.localStorage.getItem('snipcart_sessionid');
        }

        return false;
    },
    signin(snipcartSessionId) {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem('snipcart_sessionid', snipcartSessionId);

            // Redirect to page, hide cart
            setTimeout(() => {

                // Remove snipcart stuff from URL
                let url = window.location.href.replace('#/cart/signin', '');
                url = url.replace('#/cart/register', '');
                url = url.replace('#/cart', '');
                window.location.href = url;
                //window.location.reload(); // TODO: doesn't reload
            }, 100);
        }
    },
    /*
     * Signs out the current user and calls a function on success.
     */
    signout() {

        // DELETE session 
        if (typeof window !== 'undefined') {

            const url = 'https://app.snipcart.com/api/v3/customers/session';
            const options = {
                method: 'DELETE',
                crossDomain: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'x-snipcart-publicapikey': this.getSnipcartAPIKey(),
                    'x-snipcart-sessiontoken': localStorage.getItem('snipcart_sessionid'),
                    'x-snipcart-version': '3.0',
                    'x-snipcart-referer': window.location.href,
                    'x-snipcart-lang': 'de'
                }
            }

            // send POST request - create discount
            return fetch(url, options)
                .then(res => {
                    window.localStorage.removeItem('snipcart_sessionid');

                    // Reload page to update Snipcart cart and page contents
                    window.location.reload();
                })
                .catch(e => {
                    window.localStorage.removeItem('snipcart_sessionid');
                    return false;
                });

        }
    },
    /**
     * Checks if a specific course is paid for (to show all course contents).
     * @param {string} courseID 
     * @returns {Promise<boolean>} whether course has been paid for
     */
    getIsCoursePaid(courseName) {

        if (typeof window !== 'undefined' && courseName) {
            const url = 'https://app.snipcart.com/api/v3/orders?limit=100';
            const options = {
                method: 'GET',
                crossDomain: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'x-snipcart-publicapikey': this.getSnipcartAPIKey(),
                    'x-snipcart-sessiontoken': localStorage.getItem('snipcart_sessionid'),
                    'x-snipcart-version': '3.0',
                    'x-snipcart-referer': window.location.href,
                    'x-snipcart-lang': 'de'
                }
            }

            // send POST request - create discount
            return fetch(url, options)
                .then(res => {
                    return res.json();
                })
                .then(orders => {

                    // Process all orders (items + processed)
                    const paidCourses = this.processOrders(orders);
                    return paidCourses.has(courseName);
                })
                .catch(e => {
                    return false;
                });
        }

        return Promise.resolve(false);
    },
    processOrders(orders) {

        let paidCourses = new Set();
        if (orders !== undefined && orders !== null) {
            // Process all orders which are processed
            orders.map((order) => {

                // Needs to be paid
                if (order.status && order.status.indexOf('Processed') !== -1) {

                    // Check all items aka paid courses
                    if (order.items) {
                        order.items.map((orderItem) => {

                            if (orderItem.name && orderItem.name.length > 0) {
                                paidCourses.add(orderItem.name);
                            }
                            return null;
                        });
                    }
                }
                return null;
            });
        }

        return paidCourses;
    },
};
