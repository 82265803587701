import React from 'react'
import PropTypes from 'prop-types'
import { CountdownBannerWrapper, CountdownText, CountdownTimer, CountdownEl } from './CountdownBanner.styles'
import { graphql, StaticQuery } from 'gatsby'

class CountdownBanner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      elapsedSeconds: 0,
      elapsedMinutes: 0,
      elapsedHours: 0,
      elapsedDays: 0,
      difference: -1
    }
  }

  componentDidMount() {

    setInterval(() => {
      this.calculateTimer();
    }, 1000);

    //return () => clearTimeout(timer);
  }

  calculateTimer() {

    let data = {}
    if (this.props.data && this.props.data.markdownRemark && this.props.data.markdownRemark.frontmatter) {
      data = this.props.data.markdownRemark.frontmatter;
    }

    // Calc timer
    let endDateCountdown = Date.parse(data.endDateCountdown);
    let currentDate = new Date().getTime();

    let difference = endDateCountdown - currentDate;

    if (difference > 0) {

      // Calculate time difference from today
      this.setState({
        elapsedDays: Math.floor(difference / (1000 * 60 * 60 * 24)),
        elapsedHours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        elapsedMinutes: Math.floor((difference / 1000 / 60) % 60),
        elapsedSeconds: Math.floor((difference / 1000) % 60),
        difference: difference
      });
    }
  }

  render() {

    let data = {}
    if (this.props.data && this.props.data.markdownRemark && this.props.data.markdownRemark.frontmatter) {
      data = this.props.data.markdownRemark.frontmatter;
    }

    // Check start date (can't begin before this date)
    const today = new Date().getTime();
    const isCountdownActive = Date.parse(data.startDateCountdown) <= today;

    // Check if countdown is shown:
    // (1) timer should not be elapsed already => difference > 0
    // (2) timer should have started already => isCountdownActive
    // (3) countdown banner is activated => showCountdownBanner value
    if (this.state.difference > 0 && isCountdownActive && data.showCountdownBanner) {

      return(
        <CountdownBannerWrapper className={`${this.props.className || ''}`} href={data.countdownURL || ''}>
          <CountdownText>{data.countdownText}</CountdownText>
          <CountdownTimer>
            {this.state.elapsedDays > 0 && <CountdownEl>{this.state.elapsedDays.toString().padStart(2, '0')}<span>Tage</span></CountdownEl>}
            <CountdownEl>{this.state.elapsedHours.toString().padStart(2, '0')}<span>Std</span></CountdownEl>
            <CountdownEl>{this.state.elapsedMinutes.toString().padStart(2, '0')}<span>Min</span></CountdownEl>
            <CountdownEl>{this.state.elapsedSeconds.toString().padStart(2, '0')}<span>Sek</span></CountdownEl>
          </CountdownTimer>
        </CountdownBannerWrapper>);
    } else return <></>;
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
    query CountdownBannerQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          showCountdownBanner
          startDateCountdown
          endDateCountdown
          countdownText
          countdownURL
          showUSPBanner
        }
      }
    }
    `}
    render={(data) => <CountdownBanner data={data} {...props} />}
  />
)
