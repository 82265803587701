import styled from "styled-components";
import { breakpoints, colors, paddings } from "../../styles/styles";

export const BannerWrapper = styled.div`
  background-color: ${colors.background};
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  padding: 5px 0px;
  max-height: 40px;
`;

export const BannerUSPWrapper = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    display: none;
  }

  @media(${breakpoints.M}) {
    &:not(:first-child) {
      display: flex;
    }
  }
`;

export const BannerUSPText = styled.div`
`;

export const BannerUSPImage = styled.img`
  width: 24px;
`;

