import styled from "styled-components";
import { breakpoints, colors, margins, paddings } from "../../styles/styles";

export const CountdownBannerWrapper = styled.a`
  background-color: ${colors.primary};
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  z-index: 2000;
  position: relative;
`;

export const CountdownText = styled.p`
  color: white;
  margin-right: ${margins.M};
`;

export const CountdownTimer = styled.div`
  color: white;
`;

export const CountdownEl= styled.div`
  color: white;
  display: inline-block;
  border-radius: 20px;
  background-color: #ffffff8c;
  padding: 0px ${paddings.S};
  margin: ${margins.S} 4px;
  font-size: 14px;
  width: 52px;

  @media(${breakpoints.M}) {
    font-size: 20px;
    width: 64px;
  }

  span {
    color: white;
    font-size: 12px;
    margin-left: 4px;
  }
`;

