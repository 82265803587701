import React from 'react'
import '../../styles/cookies.scss'
import { CookieConfig } from './CookieConfig';

const Cookies = class extends React.Component {

  componentDidMount() {

    // Initialize Klaro cookie consent
    if (typeof window !== 'undefined') {

      var Klaro = require('klaro');
      // we assign the Klaro module to the window, so that we can access it in JS
      window.klaro = Klaro;

      window.klaroConfig = CookieConfig;

      // we set up Klaro with the config
      Klaro.setup(CookieConfig);
    }
  }

  render() {
    return (
      <></>
    )
  };
}

Cookies.propTypes = {

}

export default Cookies
