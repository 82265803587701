import styled from "styled-components";
import { breakpoints, colors, margins, paddings } from "../../styles/styles";

export const FooterWrapper = styled.footer`

    padding: calc(2* ${paddings.L}) ${paddings.L};
    display: flex;
    flex-flow: column;

    .copyright {
        padding: calc(2* ${paddings.L}) 0px;
        display: block;
        pointer-events: none;
    }

    @media(${breakpoints.L}) {
        flex-flow: row-reverse;
        justify-content: space-between;
        align-content: center;
        padding: ${paddings.XL};

        .copyright {
            padding: ${paddings.M} 0px;
        }
    }

    a:hover {
        background-color: transparent;
    }

    .navbar-item {
        background-color: transparent;
        padding: ${paddings.M} 0px;
        color: black;
        cursor: pointer;
    }

    ul {
        margin: 0px;
        display: flex;
        flex-flow: column;

        @media(${breakpoints.L}) {
            flex-flow: row;
        }
    }

    li {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: black;

        @media(${breakpoints.L}) {
            padding: 0px ${paddings.M};
        }
    }

    span {
        font-size: 16px;
        justify-content: center;

        &:hover {
            text-decoration: underline;
            color: ${colors.primary};
        }
    }
`;
export const SocialWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${margins.L};
    @media(${breakpoints.L}) {

        &:not(.navbar) {
            justify-content: right;
            margin: 0px;
        }
    }
`;

export const SocialIcon = styled.a`
    margin-right: ${margins.L};
    align-self: center;

    &:last-of-type {
        margin-right: 0px;
    }

    img {
        height: 37px;
    }
`;
